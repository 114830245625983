import React, { useState } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";

import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import { GatsbyImage } from "gatsby-plugin-image"

import { graphql } from "gatsby"

import PdfIcon from "./../images/free-pdf-download-icon-3388-thumb.png"


const Serving = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://topaper.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://topaper.gr${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    const [activeImage, setActiveImage] = useState(data?.product?.featuredImage?.node?.imageFile?.childImageSharp?.gatsbyImageData ? data.product.featuredImage.node.imageFile.childImageSharp.gatsbyImageData : '')

    return (
        <MenuContextProvider>
            <Layout serving={data.categories} metaData={constructMetaData(data.product, props.pageContext.currentPage)} >
                <HeaderOne serving={data.categories} />
                <PageHeader title={data.product.title} name={data.product.title} />

                <div className="container m-auto flex flex-wrap py-16">
                    <div className=" w-full md:w-3/5">
                        <div className="w-full" style={{ display: "grid" }}>
                            <GatsbyImage
                                style={{
                                    gridArea: "1/1",
                                    maxHeight: "400px",
                                    width: "100%",
                                    // You can set a maximum height for the image, if you wish.
                                }}
                                className="py-16"
                                alt={data.product.featuredImage.node.altText}
                                loading={"lazy"}
                                image={activeImage}
                                objectFit={"contain"}
                                formats={["auto", "webp", "avif"]}
                            />
                            <div
                                style={{
                                    // By using the same grid area for both, they are stacked on top of each other
                                    gridArea: "1/1",
                                    position: "relative",
                                    // This centers the other elements inside the hero component
                                    placeItems: "center",
                                    display: "grid",
                                }}
                            >
                                <div className="thm-container text-center">
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            {data.product.productAcf.productsGallery && data.product.productAcf.productsGallery.map((gallery, i) => (
                                <div
                                    key={`gallery-image-${i}`}
                                    className={` pt-4 cursor-pointer`}
                                    onClick={() => setActiveImage(gallery.image.imageFile.childImageSharp.gatsbyImageData)}
                                >
                                    <div className="w-full" style={{ display: "grid" }}>
                                        <GatsbyImage
                                            style={{
                                                gridArea: "1/1",
                                                maxHeight: "100px",
                                                width: "100%",
                                            }}
                                            className="py-16"
                                            alt={gallery.image.altText}
                                            loading={"lazy"}
                                            objectFit={"contain"}
                                            image={gallery.image.imageFile.childImageSharp.gatsbyImageData}
                                            formats={["auto", "webp", "avif"]}
                                        />
                                        <div
                                            style={{
                                                gridArea: "1/1",
                                                position: "relative",
                                                placeItems: "center",
                                                display: "grid",
                                            }}
                                        >
                                            <div className="thm-container text-center">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="w-full md:w-2/5 pl-0 pl-md-4 pl-lg-4 pt-8 md:pt-0">
                        {/* <h2>{data.product.title}</h2> */}
                        <div dangerouslySetInnerHTML={{ __html: data.product.content }} />
                    </div>
                </div>
                {data.product.productAcf?.productInstructionFiles && (
                    <div className="container m-auto pb-20 pt-8">
                        <h2
                            className="text-center"
                            style={{
                                fontSize: "40px",
                                fontWeight: "bold",
                                color: "#212121"
                            }}
                        >
                            Προτότυπο Αρχείο
                    </h2>
                        <p className="text-center">Κατευάστε το αρχείο και βρείτε οδηγίες για να φτιάξουμε το δικό σας προϊόν</p>
                        <a href={data.product.productAcf.productInstructionFiles.mediaItemUrl} download target="_blank">
                            <img src={PdfIcon} className="m-auto" style={{ width: "200px" }} />
                        </a>
                    </div>
                )}

                <Footer />
            </Layout>
        </MenuContextProvider>
    );
};

export default Serving;


export const pageQuery = graphql`query GET_PRODUCT($id: ID!) {
    wpgraphql {
        product(id: $id) {
            seo {
                canonical
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                    sourceUrl
                }
            }
            title
            slug
            content
            featuredImage{
                node{
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                }
            }

            productAcf{
                productsGallery{
                    image{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                            }
                        }
                    }
                }
                productInstructionFiles{
                    mediaItemUrl
                }
              }
        }
        
        categories (first:500) {
            edges {
              node {
                name
                description
                slug
                categoryPageH1{
                  categoryImage{
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                    }
                  }
                }
                children {
                  edges {
                    node {
                      name
                      posts {
                        edges {
                          node {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
    }
}
`
